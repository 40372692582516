@font-face {
    font-family: 'EarnInMori';
    src: url('../assets/fonts/EarnInMori-Regular.woff2') format('woff2'),
        url('../assets/fonts/EarnInMori-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
  font-family: 'EarnInMori';
  src: url('../assets/fonts/EarnInMori-RegularItalic.woff2') format('woff2'),
      url('../assets/fonts/EarnInMori-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
    font-family: 'EarnInMori';
    src: url('../assets/fonts/EarnInMori-Bold.woff2') format('woff2'),
        url('../assets/fonts/EarnInMori-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
  font-family: 'EarnInMori';
  src: url('../assets/fonts/EarnInMori-BoldItalic.woff2') format('woff2'),
      url('../assets/fonts/EarnInMori-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'EarnInMori';
  src: url('../assets/fonts/EarnInMori-SemiBold.woff2') format('woff2'),
      url('../assets/fonts/EarnInMori-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
font-family: 'EarnInMori';
src: url('../assets/fonts/EarnInMori-SemiBoldItalic.woff2') format('woff2'),
    url('../assets/fonts/EarnInMori-SemiBoldItalic.woff') format('woff');
font-weight: 600;
font-style: italic;
}

@font-face {
    font-family: 'EarnInMori';
    src: url('../assets/fonts/EarnInMori-ExtraBold.woff2') format('woff2'),
        url('../assets/fonts/EarnInMori-ExtraBold.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
  font-family: 'EarnInMori';
  src: url('../assets/fonts/EarnInMori-ExtraBoldItalic.woff2') format('woff2'),
      url('../assets/fonts/EarnInMori-ExtraBoldItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'EarnInMori';
  src: url('../assets/fonts/EarnInMori-Light.woff2') format('woff2'),
      url('../assets/fonts/EarnInMori-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'EarnInMori';
  src: url('../assets/fonts/EarnInMori-LightItalic.woff2') format('woff2'),
      url('../assets/fonts/EarnInMori-LightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'EarnInMori';
  src: url('../assets/fonts/EarnInMori-ExtraLight.woff2') format('woff2'),
      url('../assets/fonts/EarnInMori-ExtraLight.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'EarnInMori';
  src: url('../assets/fonts/EarnInMori-ExtraLightItalic.woff2') format('woff2'),
      url('../assets/fonts/EarnInMori-ExtraLightItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

html,
body {
    height: 100%;
}

body {
    display: flex;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-display: swap;
}

@media (max-width: 599px) {
    body {
        text-align: center;
    }
    li,
    label {
        text-align: left;
    }
}

#root {
    width: 100%;
    display: flex;
    flex-direction: column;
}